import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_S3RZpeDUYv from "/app/node_modules/nuxt-viewport/dist/runtime/plugin.mjs";
import plugin_t2GMTTFnMT from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_UC48QI1yOB from "/app/node_modules/@hypernym/nuxt-anime/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import routing_9DCz4BdToa from "/app/features/battles/plugins/routing.ts";
import useAmplitude_client_XkjlUjA7C0 from "/app/plugins/useAmplitude.client.ts";
import useApi_P5ldxlW7np from "/app/plugins/useApi.ts";
import useApp_w4Olt1izBQ from "/app/plugins/useApp.ts";
import useAuth_client_8nDJ6xl9hx from "/app/plugins/useAuth.client.ts";
import useDirectives_iLZLdmpdgy from "/app/plugins/useDirectives.ts";
import useLocalization_client_lk2036Ut9M from "/app/plugins/useLocalization.client.ts";
import useProjectSettings_ZlmWpPkoNf from "/app/plugins/useProjectSettings.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_S3RZpeDUYv,
  plugin_t2GMTTFnMT,
  i18n_yfWm7jX06p,
  plugin_UC48QI1yOB,
  chunk_reload_client_UciE0i6zes,
  routing_9DCz4BdToa,
  useAmplitude_client_XkjlUjA7C0,
  useApi_P5ldxlW7np,
  useApp_w4Olt1izBQ,
  useAuth_client_8nDJ6xl9hx,
  useDirectives_iLZLdmpdgy,
  useLocalization_client_lk2036Ut9M,
  useProjectSettings_ZlmWpPkoNf
]