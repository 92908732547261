import { useAuthStore } from '~/features/authentication/store/authorization';

export default defineNuxtPlugin(async () => {
  const authStore = useAuthStore();
  const { oldAccessToken, accessToken } = storeToRefs(authStore);

  if (!oldAccessToken.value && accessToken.value) {
    accessToken.value = undefined;
  }

  await authStore.authByExternalToken();
});
